var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"program-soc-form"},[_c('ValidationObserver',{ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',{staticClass:"px-5 py-5",attrs:{"lg":"12"}},[_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Tanggal Sosialisasi")])]),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
              label: 'Tanggal',
              validation: ['required'],
              type: 'date',
              setter: 'date',
            }},model:{value:(_vm.formData.form_date),callback:function ($$v) {_vm.$set(_vm.formData, "form_date", $$v)},expression:"formData.form_date"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
              label: 'Tahun Program',
              validation: ['required'],
              type: 'select',
              setter: 'program_year',
              option: {
                default_options: [
                  {
                    name: '2020',
                    value: '2020',
                  },
                  {
                    name: '2021',
                    value: '2021',
                  },
                  {
                    name: '2022',
                    value: '2022',
                  },
                  {
                    name: '2023',
                    value: '2023',
                  },
                  {
                    name: '2024',
                    value: '2024',
                  },
                ],

                list_pointer: {
                  code: 'value',
                  label: 'name',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.formData.program_year),callback:function ($$v) {_vm.$set(_vm.formData, "program_year", $$v)},expression:"formData.program_year"}})],1),_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("Lokasi Desa")])]),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
              label: 'Management Unit',
              validation: ['required'],
              col_size: 6,
              type: 'select',
              setter: 'mu_no',
              api: 'GetManagementUnit',
              option: {
                getterKey: 'data.result',
                list_pointer: {
                  code: 'mu_no',
                  label: 'name',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.formData.mu_no),callback:function ($$v) {_vm.$set(_vm.formData, "mu_no", $$v)},expression:"formData.mu_no"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"disabled":!_vm.formData.mu_no,"item":{
              label: 'Target Area',
              validation: ['required'],
              col_size: 6,
              type: 'select',
              setter: 'target_area',
              api: 'GetTargetArea',
              param: {
                mu_no: _vm.formData.mu_no,
              },
              option: {
                getterKey: 'data.result',
                list_pointer: {
                  code: 'area_code',
                  label: 'name',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.formData.target_area),callback:function ($$v) {_vm.$set(_vm.formData, "target_area", $$v)},expression:"formData.target_area"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"disabled":!_vm.formData.target_area || !_vm.formData.program_year,"item":{
              label: 'Desa',
              validation: ['required'],
              col_size: 6,
              type: 'select',
              setter: 'village',
              api: 'GetDesa',
              param: {
                program_year: _vm.formData.program_year,
                kode_ta: _vm.formData.target_area,
              },
              option: {
                getterKey: 'data.result',
                list_pointer: {
                  code: 'kode_desa',
                  label: 'name',
                  display: ['name'],
                },
              },
            }},model:{value:(_vm.formData.village),callback:function ($$v) {_vm.$set(_vm.formData, "village", $$v)},expression:"formData.village"}})],1),(_vm.formData.mu_no)?_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"align-items":"center"}},[_c('h4',{staticClass:"mb-0 pb-0"},[_vm._v("Daftar Petani")]),_c('v-btn',{staticClass:"circle ml-3",attrs:{"small":"","variant":"success"},on:{"click":function($event){return _vm.addRow()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]):_vm._e(),(_vm.formData.mu_no)?_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"bg-grey"},_vm._l((_vm.participants),function(item,i){return _c('v-expansion-panels',{key:'acc' + i + i,attrs:{"focusable":""}},[_c('v-expansion-panel',{staticClass:"mb-3"},[_c('v-expansion-panel-header',[_c('span',{class:{
                      'text-italic text-grey': !item.name,
                    }},[_vm._v(_vm._s(item.name || "Masukkan Data Petani"))]),(_vm.participants.length > 1)?_c('div',{staticClass:"d-flex flex-row",staticStyle:{"justify-content":"flex-end"}},[_c('button',{staticClass:"text-danger",on:{"click":function($event){return _vm.deleteRow(i)}}},[_c('v-icon',{staticClass:"text-danger"},[_vm._v("mdi-delete-empty")])],1)]):_vm._e()]),_c('v-expansion-panel-content',{staticClass:"pt-4 pb-5"},[_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                          label: 'Nama Petani',
                          validation: ['required'],
                          type: 'text',
                          setter: 'name',
                        }},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                          label: 'Berminat?',
                          validation: ['required'],
                          type: 'select-radio',
                          setter: 'status_program',
                          option: {
                            list_pointer: {
                              label: 'label',
                              code: 'code',
                              display: ['label'],
                            },
                            default_options: [
                              {
                                label: 'Ya',
                                code: 'Ya',
                              },
                              {
                                label: 'Tidak',
                                code: 'Tidak',
                              },
                              {
                                label: 'Ragu - Ragu',
                                code: 'Ragu - Ragu',
                              },
                            ],
                          },
                        }},model:{value:(item.status_program),callback:function ($$v) {_vm.$set(item, "status_program", $$v)},expression:"item.status_program"}})],1),(item.status_program === 'Ya')?_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                          label: 'Pola Tanam Sebelumnya',
                          validation: ['required'],
                          type: 'select',
                          setter: 'pattern',
                          option: {
                            list_pointer: {
                              label: 'text',
                              code: 'value',
                              display: ['text'],
                            },
                            default_options: _vm.defaultData.planting_pattern,
                          },
                        }},model:{value:(item.pattern),callback:function ($$v) {_vm.$set(item, "pattern", $$v)},expression:"item.pattern"}})],1):_vm._e(),(
                        ['Ya', 'Ragu - Ragu'].includes(item.status_program)
                      )?_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                          label: 'Materi Pelatihan',
                          validation: ['required'],
                          type: 'select',
                          setter: 'training',
                          api: 'GetTrainingMaterials',
                          option: {
                            getterKey: 'data.result',
                            list_pointer: {
                              label: 'material_name',
                              code: 'material_no',
                              display: ['material_name'],
                            },
                          },
                        }},model:{value:(item.training),callback:function ($$v) {_vm.$set(item, "training", $$v)},expression:"item.training"}})],1):_vm._e(),(
                        ['Ya'].includes(item.status_program) &&
                        !['007', '019', '015', '008', '014', '016'].includes(
                          _vm.formData.mu_no
                        )
                      )?_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                          validation: ['required'],
                          label: 'Status legalitas lahan yang dimiliki',
                          type: 'select-radio',
                          option: {
                            default_options:
                              _vm.defaultData.owned_land_legalization_status,
                            list_pointer: {
                              code: 'code',
                              name: 'name',
                              display: ['name'],
                            },
                          },
                        }},model:{value:(item.owned_land_legalization_status),callback:function ($$v) {_vm.$set(item, "owned_land_legalization_status", $$v)},expression:"item.owned_land_legalization_status"}})],1):_vm._e(),(
                        ['Ya'].includes(item.status_program) &&
                        !['007', '019', '015', '008', '014', '016'].includes(
                          _vm.formData.mu_no
                        )
                      )?_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                          validation: ['required'],
                          label: 'Model project yang akan diikuti',
                          type: 'select-radio',
                          option: {
                            default_options:
                              _vm.defaultData.followed_project_model,
                            list_pointer: {
                              code: 'code',
                              name: 'name',
                              display: ['name'],
                            },
                          },
                        }},model:{value:(item.followed_project_model),callback:function ($$v) {_vm.$set(item, "followed_project_model", $$v)},expression:"item.followed_project_model"}})],1):_vm._e(),(['Ya'].includes(item.status_program))?_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                          label: 'Pohon Yang Diminati (maks 3)',
                          validation: ['required'],
                          type: 'select',
                          setter: 'training',
                          api: 'GetTreesAll',
                          option: {
                            multiple: true,
                            getterKey: 'data.result.data',
                            list_pointer: {
                              label: 'tree_name',
                              code: 'tree_code',
                              display: ['tree_name'],
                            },
                          },
                        }},on:{"selected":function($event){return _vm.onSelectTree(i, item.trees)}},model:{value:(item.trees),callback:function ($$v) {_vm.$set(item, "trees", $$v)},expression:"item.trees"}})],1):_vm._e(),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                          label: 'Foto Minat',
                          validation: ['required'],
                          type: 'upload',
                          api: 'sosialisasi_program/upload.php',
                          directory: 'photos',
                          upload_type: 'image/*',
                          setter: 'photo',
                          view_data: 'farmer_photo_' + i,
                          option: {
                            label_hint:
                              'Klik gambar untuk memilih berkas yang akan diunggah',
                            max_size: 5,
                            multiple: false,
                          },
                        }},model:{value:(item.photo),callback:function ($$v) {_vm.$set(item, "photo", $$v)},expression:"item.photo"}})],1)],1)],1)],1)],1)}),1)]):_vm._e(),_c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"justify-content":"flex-end"}},[_c('v-btn',{attrs:{"variant":"success","type":"submit","disabled":_vm.loading}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_vm._v("Tambah Data")])],1)],1)])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }